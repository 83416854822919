body {
  background-color: #00000011 !important;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans', sans-serif;
}

code,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
body > img {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiTableCell-root {
  padding: 8px 12px;
}

.containerAnchorOriginBottomRight {
  z-index: 99999999999999999;
}

.multilineTruncate {
  overflow: hidden;
  position: relative;
  max-height: 36px;
  cursor: pointer;
  line-height: 18px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
}

.renderedHtmlNoHeight {
  line-height: 18px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  height: 100%;
  white-space: pre-wrap;
}

.renderedHtmlNoHeight::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.renderedHtml {
  line-height: 18px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  height: 100%;
  max-height: 46vh;
  white-space: pre-wrap;
}

.renderedHtml::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.renderedTextNoHeight {
  margin: 0;
  line-height: 18px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  height: 100%;
  white-space: pre-wrap;
}

.renderedTextNoHeight::-webkit-scrollbar {
  margin: 0;
  width: 15px;
  height: 15px;
}

.renderedText {
  margin: 0;
  line-height: 18px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  height: 100%;
  max-height: 46vh;
  white-space: pre-wrap;
}

.renderedText::-webkit-scrollbar {
  margin: 0;
  width: 15px;
  height: 15px;
}

.dense {
  padding: 4px 8px;
}

.error {
  background-color: #f44336 !important;
  color: #fff !important;
}
.error-light {
  background-color: #e57373 !important;
  color: #fff !important;
}
.error-dark {
  background-color: #d32f2f !important;
  color: #fff !important;
}
.error-border {
  margin-right: 0.5em;
  margin-top: 0.2em;
  border-color: #d32f2f !important;
  color: #d32f2f !important;
}
.error-border span,
.error-border svg,
.error-border div {
  color: inherit !important;
  background-color: inherit !important;
}

.warning {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.warning-light {
  background-color: #ffb74d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.warning-dark {
  background-color: #f57c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.warning-border {
  margin-right: 0.5em;
  margin-top: 0.2em;
  background-color: #f57c00 !important;
  border-color: #f57c00 !important;
  color: #ffffff !important;
}
.warning-border span,
.warning-border svg,
.warning-border div {
  color: inherit !important;
}

.info {
  background-color: #2196f3 !important;
  color: #fff !important;
}
.info-light {
  background-color: #64b5f6 !important;
  color: #fff !important;
}
.info-dark {
  background-color: #1976d2 !important;
  color: #fff !important;
}
.info-border {
  margin-right: 0.5em;
  margin-top: 0.2em;
  border-color: #1976d2 !important;
  color: #1976d2 !important;
}
.info-border span,
.info-border svg,
.info-border div {
  color: inherit !important;
  background-color: inherit !important;
}

.success {
  background-color: #4caf50 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.success-light {
  background-color: #81c784 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.success-dark {
  background-color: #388e3c !important;
  color: #fff !important;
}
.success-border {
  margin-right: 0.5em;
  margin-top: 0.2em;
  border-color: #388e3c !important;
  color: #388e3c !important;
}
.success-border span,
.success-border svg,
.success-border div {
  color: inherit !important;
  background-color: inherit !important;
}

/* styles for '...' */

button:visited,
button:target,
a:visited,
a:target {
  color: errorOnPurpose;
  text-decoration: errorOnPurpose;
}

@keyframes blinkingErrorBackground {
  0% {
    background-color: inherit;
  }
  20% {
    background-color: orangered;
  }
  40% {
    background-color: inherit;
  }
  60% {
    background-color: orangered;
  }
  100% {
    background-color: inherit;
  }
}
